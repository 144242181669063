import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Direccion.css';
import Mapa from '../Mapa/Mapa';
import Fade from 'react-reveal/Fade';
export default function Direccion() {
	return (
		<div>
			<Container fluid className="section5">
				<Row>
					<Col
						lg={{ span: 4 }}
						md={{ span: 4 }}
						sm={{ span: 12 }}
						xs={{ span: 12 }}
						className="infoSection5"
						style={{
						
						}}
					>
						<Fade cascade delay={300}>
							<h1 className="textoInfoSection5 tA" style={{ fontSize: ' calc(1.2rem + 1.2vw)', fontSizeAdjust: 0.6 }}>
								Salta, Argentina
							</h1>
							<h5 className="textoInfoSection5 tB" style={{ fontSize: ' calc(.9rem + .9vw)' }}>
								Rivadavia 960
							</h5>
							<br />
							<h2 className="textoInfoSection5 tC" style={{ fontSize: ' calc(1.1rem + 1.1vw)' }}>
								Lunes a Viernes
							</h2>
							<h4 className="textoInfoSection5 tD" style={{ fontSize: ' calc(.8rem + .8vw)' }}>
								8:30 - 10:30 y 17:00 - 21:30
							</h4>
							<br />

							<h1 className="textoInfoSection5 tE" style={{ fontSize: ' calc(1.3rem + 1.3vw)' }}>
								+5493875366716
							</h1>
						</Fade>
					</Col>
					<Col
						lg={{ span: 8 }}
						md={{ span: 8 }}
						sm={{ span: 12 }}
						xs={{ span: 12 }}
						className="mapaSection5"
						style={{ paddingLeft: '0px' }}
					>
						<div>
							<Mapa />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
