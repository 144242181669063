import React from 'react';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
export const SidebarData = [
	{
		title: 'Novedades',
		path: '/admin/novedades',
		icon: <NewReleasesIcon />
	},
	{
		title: 'Noticias',
		path: '/admin/noticias',
		icon: <FeaturedPlayListIcon />
	},
	// {
	// 	title: 'Cuenta',
	// 	icon: <AccountBoxIcon />,
	// 	iconClosed: <ExpandMoreIcon />,
	// 	iconOpened: <ExpandLessIcon />,

	// 	subNav: [
	// 		{
	// 			title: 'Cerrar Sesion',
	// 			icon: <ExitToAppIcon />
	// 		}
	// 	]
	// }
];
