import React, {  } from 'react';
import { Alert, Button, Col, Container, Form,  Row, Spinner } from 'react-bootstrap';
import { Formik } from 'formik'; // Importamos el component <Formik />
import Logo from '../../../../assets/images/logonegro.png';
import { useAuthDispatch, useAuthState } from '../../../Context/LoginRegister/context';
import { loginUser } from '../../../Context/LoginRegister/actions';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import Background from '../../../../assets/images/builds.png';

const yup = require('yup');

const schema = yup.object({
	email: yup.string().email('El email es requerido').required('El email es requerido'),
	password: yup
		.string()
		.required('La contraseña es requerida')
		.min(6, 'La contraseña es corta - deberia ser de 6 caracteres mínimo')
});

export default function LoginAdmin() {
	const dispatch = useAuthDispatch();
	const stateAuth = useAuthState();

	let history = useHistory();

	const handleSubmit = async (values) => {
		try {
			let response = await loginUser(dispatch, { email: values.email, password: values.password });
			if (!response.user) return;
			history.push('/admin');
		} catch (error) {}
	};
	return (
		<Container
			style={{
				display: 'flex',
				flexDirection: 'column',
				minHeight: '100vh'
			}}
			className="pt-md-5 pb-md-5 "
		>
			<Row
				style={{
					boxShadow: '0px 0px 13px 5px rgba(0,0,0,0.29)',
					display: 'flex',
					flex: 1
				}}
			>
				<Col
					className="pl-0 pr-0 d-none d-sm-none d-md-block d-lg-block d-xl-block"
					xs={{ order: 'last', span: 12 }}
					sm={{ order: 'last', span: 12 }}
					md={6}
					xl={6}
				>
					<div
						style={{
							display: 'flex',
							flex: '1',
							flexDirection: 'column',
							justifyContent: 'center',
							height: '100%',
							alignItems: 'center',
							backgroundImage: `url(${Background})`,
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover'
						}}
					>
						<motion.h1 style={{ textAlign: 'center' }}>Bienvenido!</motion.h1>
						<motion.h5 style={{ textAlign: 'left', width: '70%' }}>
							Para poder administrar el sitio por favor inicie sesión o registrese..
						</motion.h5>
						<Button className="float-md-right btn-enviar bg-white" type="submit">
							Registrarse
						</Button>
					</div>
				</Col>
				<Col
					className="pl-2 pr-2"
					xs={{ order: 'first', span: 12 }}
					sm={{ order: 'first', span: 12 }}
					md={{ order: 'last', span: 6 }}
					xl={{ order: 'last', span: 6 }}
				>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'space-around',
							height: '100%',
							minHeight: '85vh'
						}}
					>
						<motion.img
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 1 }}
							className="m-3"
							src={Logo}
							style={{ height: '100px', marginBottom: '100px' }}
						/>
						<h2 style={{ textAlign: 'center' }}>Iniciar sesión</h2>
						<Formik
							validationSchema={schema}
							onSubmit={handleSubmit}
							initialValues={{
								email: '',
								password: ''
							}}
						>
							{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
			 					<Form
									noValidate
									onSubmit={handleSubmit}
									className="pl-2 pr-3  pl-sm-3 pr-sm-3 pl-md-5 pr-md-5 pl-lg-5 pr-lg-5 pl-xl-5  pr-xl-5"
								>
									<Form.Row>
										<Form.Group as={Col} md="12" controlId="validationFormik01">
											{/* <Form.Label>First name</Form.Label> */}
											<Form.Control
												type="text"
												name="email"
												value={values.email}
												onChange={handleChange}
												isValid={touched.email && !errors.email}
												isInvalid={!!errors.email}
												style={{
													border: '0',
													borderBottom: '1px solid rgba(66,62,55,.2)',
													borderRadius: 0,
													alignSelf: 'center'
												}}
											/>
											<Form.Text
												className="text-muted h5"
												style={{ color: 'rgba(66,62,55,.2) !important' }}
											>
												Email:
											</Form.Text>
											<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
											<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
										</Form.Group>
										<Form.Group as={Col} md="12" controlId="validationFormik02">
											{/* <Form.Label>Last name</Form.Label> */}
											<Form.Control
												type="password"
												name="password"
												value={values.password}
												onChange={handleChange}
												isValid={touched.password && !errors.password}
												isInvalid={!!errors.password}
												style={{
													border: '0',
													borderBottom: '1px solid rgba(66,62,55,.2)',
													borderRadius: 0
												}}
											/>
											<Form.Text
												className="text-muted h5"
												style={{ color: 'rgba(66,62,55,.2) !important' }}
											>
												Contraseña
											</Form.Text>
											<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
											<Form.Control.Feedback
												style={{ width: '90%' }}
												type="invalid"
											>
												{errors.password}
											</Form.Control.Feedback>
										</Form.Group>
									</Form.Row>
									<Form.Row>
										<Form.Group as={Col} md="12">
											<Button className="float-md-center btn-login" type="submit">
												{stateAuth.loading ? (
													<Spinner
														as="span"
														animation="border"
														size="sm"
														role="status"
														aria-hidden="true"
														className="ml-2 mr-2"
													/>
												) : (
													<div />
												)}
												Ingresar
											</Button>
										</Form.Group>
									</Form.Row>
									<Form.Row>
										{stateAuth.errorMessage != null ? (
											<Alert variant="danger" className="w-100 ">
												{stateAuth.errorMessage}
											</Alert>
										) : (
											<div />
										)}
									</Form.Row>
								</Form>
							)}
						</Formik>
					</div>
				</Col>
			</Row>
		</Container>
	);
}
