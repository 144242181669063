import axios from 'axios';

const ROOT_URL = 'https://api.estudioeje.com.ar/api';

export async function loginUser(dispatch, loginPayload) {
	// const requestOptions = {
	// 	method: 'POST',
	// 	headers: { 'Content-Type': 'application/json' },
	// 	body: JSON.stringify(loginPayload)
	// };
	// console.log(JSON.stringify(loginPayload));
	let errorMessage;
	try {
		dispatch({ type: 'REQUEST_LOGIN' });
		let data;
		await axios
			.post(`${ROOT_URL}/login`, { email: loginPayload.email, password: loginPayload.password })
			.then((res) => {
				// console.log('por then');
				data = res.data;
			})
			.catch((e) => {
				// console.log('por catch');
				errorMessage = e.response.data.message;
			});
		// await axios
		// 	.post('http://127.0.0.1:8000/api/login', { email: values.email, password: values.password })
		// 	.then((res) => {
		// 		console.log(res);
		// 	})
		// 	.catch((e) => {
		// 		console.log(e);
		// 	});

		if (data.user) {
			// console.log('si entra toy hata el pingo');
			dispatch({ type: 'LOGIN_SUCCESS', payload: data });
			localStorage.setItem('currentUser', JSON.stringify(data.user));
			localStorage.setItem('token', JSON.stringify(data.access_token));
			return data;
		}
	} catch (error) {
		// console.log('diayy');

		dispatch({ type: 'LOGIN_ERROR', error: errorMessage });
	}
}

export async function logout(dispatch) {
	dispatch({ type: 'LOGOUT' });
	localStorage.removeItem('currentUser');
	localStorage.removeItem('token');
}
