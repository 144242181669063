import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "./Inicio.css";
import { motion } from "framer-motion";
import { animationTwo } from "../Animations/animation";
import Galeria from "../Galeria/Galeria";
import Servicios from "../Servicios/Servicios";
import NavBar1 from "../NavBar/NavBar1";
import Contacto from "../Contacto/Contacto";
import Equipo from "../Equipo/Equipo";
import Direccion from "../Direccion/Direccion";
import Footer from "../Footer/Footer";
import Portada from "../Portada/Portada";
import GaleriaCarousel from "../GaleriaCarousel/GaleriaCarousel";
import QueOfrecemos from "../QueOfrecemos/QueOfrecemos";
import QuienesSomos from "../QuienesSomos/QuienesSomos";
import MetaTags from "react-meta-tags";
export default function Inicio() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={animationTwo}
      style={{ padding: "0px", margin: "0px", backgroundColor: "#FFFAF4" }}
    >
      <MetaTags>
        <title>Home - Estudio Eje</title>
        <meta
          name="description"
          content="Nosotros nos encargamos de solucionarte tus cosas"
        />
        <meta property="og:title" content="Home - Estudio Jurídico EJE" />
        <meta property="og:image" content="../../../public/logocolor.png" />
      </MetaTags>
      <NavBar1 />
      <Portada />
      <QuienesSomos />
      <Servicios />
      <Contacto />
      <Equipo />
      <QueOfrecemos />
      <Galeria />
      <GaleriaCarousel />
      <div style={{ width: "100%", height: "10vh" }} />
      <Direccion />
      <div style={{ width: "100%", height: "10vh" }} />
      <Footer />
    </motion.div>
  );
}
