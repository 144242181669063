import { motion } from "framer-motion";
import React from "react";
import { Container } from "react-bootstrap";

const imagenes = [
  {
    nombre: "A",
    imagen: require("../../assets/images/galeria/galeria1.jpg"),
    thumbnail: "Eje Estudio ",
  },
  {
    nombre: "B",
    imagen: require("../../assets/images/galeria/galeria2.jpg"),
    thumbnail: "Eje Estudio ",
  },
  {
    nombre: "C",
    imagen: require("../../assets/images/galeria/galeria3.jpg"),
    thumbnail: "Eje Estudio ",
  },
  {
    nombre: "D",
    imagen: require("../../assets/images/galeria/galeria5.jpg"),
    thumbnail: "Eje Estudio ",
  },
  {
    nombre: "E",
    imagen: require("../../assets/images/galeria/galeria4.jpg"),
    thumbnail: "Eje Estudio ",
  },

  {
    nombre: "F",
    imagen: require("../../assets/images/galeria/galeria11.jpg"),
    thumbnail: "Eje Estudio ",
  },
  {
    nombre: "G",
    imagen: require("../../assets/images/galeria/galeria8.jpg"),
    thumbnail: "Eje Estudio ",
  },

  {
    nombre: "H",
    imagen: require("../../assets/images/galeria/H.jpg"),
    thumbnail: "Eje Estudio ",
  },
];
export default function GridImg({ setSelectedImg }) {
  return (
    <div>
      <Container
        fluid
        className="p-md-0 grid-container"
        style={{
          backgroundColor: "black",
        }}
      >
        {imagenes.map((imagen, index) => {
          return (
            <div
              layout
              key={index}
              onClick={() => {
                document.body.style.overflow = "hidden";
                setSelectedImg(imagen.imagen);
              }}
              className={`${imagen.nombre} img-wrap imgGaleria`}
              style={{
                overflow: "hidden",
                backgroundColor: "black",
              }}
            >
              <img
                alt=""
                style={{
                  width: "100%",
                  height: "130%",
                  objectFit: "cover",
                }}
                src={imagen.imagen}
              />
            </div>
          );
        })}
      </Container>
    </div>
  );
}
