import React from 'react';
import './App.css';
import { Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import AppRoutes from './Components/AppRoute/AppRoute';
import routes from './config/routes';
import { AuthProvider } from './Components/Context/LoginRegister';

function App() {
	let location = useLocation();

	return (
		<div
			style={{
				boxSizing: 'border-box',
				padding: '0px',
				margin: '0px',
				overflowX: 'hidden',
				overflowY: 'hidden',
				height: '100%'
			}}
		>
			<AuthProvider>
				<AnimatePresence exitBeforeEnter>
					<Switch location={location}>
						{routes.map((route) => (
							<AppRoutes
								key={route.path}
								path={route.path}
								component={route.component}
								isPrivate={route.isPrivate}
							/>
						))}
					</Switch>
				</AnimatePresence>
			</AuthProvider>
		</div>
	);
}

export default App;
