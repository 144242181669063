import React from "react";
import { Jumbotron, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import NavAdmin from './Components/NavAdmin/NavAdmin';
import { Route, useRouteMatch } from "react-router-dom";
import NoticiasAdmin from "./Components/NoticiasAdmin/NoticiasAdmin";
import NovedadesAdmin from "./Components/NovedadesAdmin/NovedadesAdmin";
import { useAuthDispatch } from "../Context/LoginRegister";
import Sidebar from "./Components/SideBar/Sidebar";
import { MetaTags } from "react-meta-tags";

export default function InicioAdmin() {
  const { url, path } = useRouteMatch();
  const dispatch = useAuthDispatch();
  // console.log(url, path);

  return (
    <div>
      {/* <NavAdmin /> */}
      <MetaTags>
        <title>Admin - Estudio Eje</title>
        <meta
          name="description"
          content="Nosotros nos encargamos de solucionarte tus cosas"
        />
        <meta property="og:title" content="Admin - Estudio Jurídico EJE" />
        <meta property="og:image" content="../../../public/logocolor.png" />
      </MetaTags>
      <Sidebar />
      <Jumbotron>
        <h1>Hola, de nuevo!</h1>
        <p>
          En este panel de administrador, podrás agregar, editar y eliminar
          tanto noticias como novedades.
        </p>
        <p>
          <Link
            to={`${url}/noticias`}
            style={{
              textDecoration: "none",
            }}
          >
            <Button variant="secondary btn-enviar">Noticias</Button>
          </Link>
          <Link
            to={`${url}/novedades`}
            style={{
              textDecoration: "none",
            }}
          >
            <Button variant="secondary btn-enviar">Novedades</Button>
          </Link>
        </p>
      </Jumbotron>
      <Route path={`${path}/noticias`}>
        <NoticiasAdmin />
      </Route>
      <Route path={`${path}/novedades`}>
        <NovedadesAdmin />
      </Route>
    </div>
  );
}
