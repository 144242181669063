import { motion } from 'framer-motion';
import React from 'react';

export default function Spinner({ height, width }) {
	return (
		<div
			style={{
				height: height,
				width: width,
				backgroundColor: 'transparent',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}
		>
			<div
				style={{
					height: '23%',
					width: '100%',
					backgroundColor: 'transparent',
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				<motion.div
					initial={{ x: '50%', opacity: 0.25 }}
					animate={{ x: '0%', opacity: 1 }}
					transition={{
						yoyo: Infinity,
						duration: 0.75,
						ease: 'easeOut'
					}}
					style={{
						clipPath: 'polygon(10% 0, 90% 0, 100% 100%, 0% 100%)',
						backgroundColor: 'rgba(0, 123, 130, 1)',
						height: '100%',
						width: '60%'
					}}
				/>
			</div>
			<div
				style={{
					height: '23%',
					width: '100%',
					backgroundColor: 'transparent',
					display: 'flex',
					justifyContent: 'center'
				}}
			>
				<motion.div
					initial={{ x: '50%', opacity: 0.25 }}
					animate={{ x: '0%', opacity: 1 }}
					transition={{
						yoyo: Infinity,
						duration: 0.75,
						ease: 'easeOut',
						delay: 0.12
					}}
					style={{
						clipPath: 'polygon(10% 0, 90% 0, 100% 100%, 0% 100%)',
						backgroundColor: 'rgba(0, 123, 130, 1)',
						height: '100%',
						width: '80%'
					}}
				/>
			</div>
			<div style={{ height: '23%', width: '100%', backgroundColor: 'transparent' }}>
				<motion.div
					initial={{ x: '50%', opacity: 0.45 }}
					animate={{ x: '0%', opacity: 1 }}
					transition={{
						yoyo: Infinity,
						duration: 0.75,
						ease: 'easeOut',
						delay: 0.24
					}}
					style={{
						clipPath: 'polygon(10% 0, 90% 0, 100% 100%, 0% 100%)',
						backgroundColor: 'rgba(0, 123, 130, 1)',
						height: '100%',
						width: '100%'
					}}
				/>
			</div>
		</div>
	);
}
