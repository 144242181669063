import { Modal, Button, Alert, Spinner } from 'react-bootstrap';
import React, { useState } from 'react';
import axios from 'axios';

export default function ModalDeleteNovedad({ show, novedad, handleClose, handleIsDelete, handleGetNovedades }) {
	const [ messageResponse, setMessageResponse ] = useState();
	const [ uploadPercentage, setUploadPercentage ] = useState();
	const [ loading, setLoading ] = useState(false);

	const deleteNovedad = async () => {
		setLoading(true);

		const options = {
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;
				let percent = Math.floor(loaded * 100 / total);
				// console.log(`${loaded}kb of ${total}kb | ${percent}%`);
				if (percent < 100) {
					setUploadPercentage(percent);
				}
			},
			headers: {
				Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
			}
		};
		await axios
			.delete(`https://api.estudioeje.com.ar/api/novedades/${novedad.id}`, options)
			.then((res) => {
				// console.log(res);
				setLoading(false);
				setMessageResponse(res.data.message);
				handleGetNovedades();
				setTimeout(() => {
					handleClose(true);
					handleIsDelete(false);
				}, 1000);
			})
			.catch((e) => {
				// console.log(e);
				setLoading(false);
				setMessageResponse(e.message);
			});
	};
	return (
		<div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Eliminar Novedad</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="pl-2">
						<h4 color="black">Detalles</h4>
						<h5 style={{ color: 'gray' }}>
							Titulo <h6 color="gray">{novedad.titulo}</h6>
						</h5>
						<h5 style={{ color: 'gray' }}>
							Cuerpo <h6 color="gray">{novedad.cuerpo}</h6>
						</h5>
					</div>
					{messageResponse != null ? (
						<Alert variant="info" className="w-100 ">
							{messageResponse}
						</Alert>
					) : (
						<div style={{ display: 'none' }} />
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => {
							handleClose(false);
							handleIsDelete(false);
						}}
					>
						Cancelar
					</Button>
					<Button variant="danger" onClick={deleteNovedad}>
						{loading ? (
							<Spinner animation="border" size="sm" style={{ marginBottom: '2px', marginRight: '3px' }} />
						) : (
							<div style={{ display: 'none' }} />
						)}
						Eliminar novedad
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
