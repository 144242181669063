import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './QueOfrecemos.css';
import Fade from 'react-reveal/Fade';

import icono1 from '../../assets/images/queofrecemos/1.png';
import icono2 from '../../assets/images/queofrecemos/2.png';
import icono3 from '../../assets/images/queofrecemos/3.png';
import icono4 from '../../assets/images/queofrecemos/4.png';
import icono5 from '../../assets/images/queofrecemos/5.png';
import icono6 from '../../assets/images/queofrecemos/6.png';
import icono7 from '../../assets/images/queofrecemos/7.png';
import icono8 from '../../assets/images/queofrecemos/8.png';

const items = [
	{
		id: 1,
		nombre: 'Disponibilidad 24/7',
		cuerpo:
			"Sabemos que en la defensa de tus derechos y seguridad, la premura en atenderte es fundamental para garantizar el posterior resultado. Es por ello, que nuestros especialistas cuentan con amplia disponibilidad horaria y movilidad para tu asistencia legal.",
		imagen: icono1
	},
	{
		id: 2,
		nombre: 'Diferentes medios de pago',
		cuerpo:
			'Contamos con diferentes medios y formas de pagos. Entre los que se destacan: efectivo, tarjetas de créditos, débitos, Mercado Pago, modo/ Viumi de Banco Macro, Toque de Tarjeta Naranja, abonos mensuales y anuales, convenios privados de honorarios.',
		imagen: icono2
	},
	{
		id: 3,
		nombre: 'Equipo multilenguaje',
		cuerpo:
			"Es fundamental la interconectividad en nuestros días, nuestros profesionales estan capacitados para brindar tu asesoramiento en el lenguaje que tu caso lo requiera.",
		imagen: icono3
	},
	{
		id: 4,
		nombre: 'Abogados experimentados',
		cuerpo:
			'A lo largo de los años, nuestros profesionales han tenido la oportunidad de litigar en los distintos fueros y materias de su especialización, lo que se traduce en un importante valor agregado a la hora de plantear y resolver tu caso.',
		imagen: icono4
	},
	{
		id: 5,
		nombre: 'Servicio completo',
		cuerpo:
			"Nos especializamos en las diferentes ramas del derecho, trabajamos mancomunadamente con equipo de profesionales interdisciplinarios según lo amerite la causa a tratar.",
		imagen: icono5
	},
	{
		id: 6,
		nombre: 'Capacitaciones constantes',
		cuerpo:
			'El derecho evoluciona y se transforma constantemente a la par de la sociedad, por ello nos capacitamos y formamos continuamente a partir de la oferta académica de las mas prestigiosas universidades para asi estar a la vanguardia del análisis y resolución de cada caso en particular.',
		imagen: icono6
	},
	// {
	// 	id: 7,
	// 	nombre: 'Gran trayectoria y experiencia',
	// 	cuerpo:
	// 		'Culpa sint consequat dolor esse eiusmod consequat consectetur nisi officia. Nisi sit proident id consectetur incididunt ullamco labore nulla incididunt officia irure id sint nostrud.',
	// 	imagen: icono7
	// },
	{
		id: 8,
		nombre: 'Fuero Federal',
		cuerpo:
			'Todo el equipo está habilitado y experimentado para litigar en el fuero federal, no solo atendiendo causas provinciales sino también en todo el interior del país.',
		imagen: icono8
	}
];

export default function QueOfrecemos() {
	return (
		<Container  className="containerQueOfrecemos pl-md-5 pr-md-5 pl-lg-5 pr-lg-5 pl-xl-5 pr-xl-5">
			<Fade delay={500}>
				<div className="titulo" style={{ paddingTop: '5vh', paddingBottom: '5vh' }}>
					De nosotros a nuestros clientes
				</div>
			</Fade>
			<Row>
				{items.map((item, index) => {
					return (
						<Col
							lg={{ span: 6 }}
							md={{ span: 6 }}
							sm={{ span: 12 }}
							xs={{ span: 12 }}
							className="colContainerItem"
						>
							<Fade delay={400} cascade>
								<Row>
									<Col xs={{ span: 12 }} sm={{span:12}}  md={{span:3}}  lg={{span:3}}  xl={{span:3}} className="colImgItem">
										<img alt="" className="imgItem" src={item.imagen} />
									</Col>
									<Col xs={{ span: 12 }} sm={{span:12}} md={{span:9}}  lg={{span:9}}  xl={{span:9}}  className="colTextoItem">
										<h4 className="tituloItem">{item.nombre}</h4>
										<p className="cuerpoItem">{item.cuerpo}</p>
									</Col>
								</Row>
							</Fade>
						</Col>
					);
				})}
			</Row>
		</Container>
	);
}
