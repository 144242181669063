import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import './NavBar2.css';
import {animateScroll as scroll } from 'react-scroll';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logonegro.png';

export default function NavBar2() {
	const [ show, handleShow ] = useState(false);
	const fadeUp = {
		hidden: { opacity: 0, y: -50 },
		visible: { opacity: 1, y: 0 }
	};

	useEffect(() => {
		scroll.scrollTo(0)
		const handleScroll = () => {
			if (window.scrollY > 160) {
				handleShow(true);
			} else handleShow(false);
		};
		document.addEventListener('scroll', handleScroll);
		return () => {
			document.removeEventListener('scroll', handleScroll);
		};
	}, []);
	return (
		<div>
			<motion.nav
				variants={fadeUp}
				initial="hidden"
				animate="visible"
				transition={{ duration: 1 }}
				className={`navbar-custom2 ${show && 'navbar-custom-dark2'}`}
			>
				<input id="nav-toggle" type="checkbox" />
				<div className="logo1">
					<Link className="liNav2" to="/">
						<img alt="" className="imgLogo" src={Logo} />
					</Link>
				</div>
				<ul className="linkss">
					<li className="">
						<Link className="liNav2" to="/">
							Nosotros
						</Link>
					</li>
					<li className="">
						<Link className="liNav2" to="/" onClick={() => scroll.scrollToTop()}>
							Servicios
						</Link>
					</li>
					<li>
						<div className="logo">
							<Link className="liNav2" to="/">
								<img alt="" className="imgLogo" src={Logo} />
							</Link>
						</div>
					</li>
					<li className="">
						<Link className="liNav2" to="/">
							Equipo
						</Link>
					</li>
					<li className="">
						<NavLink
							activeStyle={{
								fontWeight: 'bolder',
								color: 'blue'
							}}
							className="liNav2"
							to="/noticias"
						>
							Noticias
						</NavLink>
					</li>
				</ul>
				<div className="btn-menu">
					<div className="line" />
					<div className="line" />
				</div>
				<label for="nav-toggle" className="icon-burger2">
					<div className="line" />
					<div className="line" />
				</label>
			</motion.nav>
		</div>
	);
}
