import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './Equipo.css';
import Gabriel from '../../assets/images/individual/gabriel.jpg';
import Fernando from '../../assets/images/individual/fernando.jpg';
import Federico from '../../assets/images/individual/federico1.jpg';
import Marcela from '../../assets/images/individual/marcela.jpg';
import Mauricio from '../../assets/images/individual/mauricio.jpg';
import Julio from '../../assets/images/individual/julio.jpg';
import Jose from '../../assets/images/individual/Dr1.jpg';
import Diego from '../../assets/images/individual/Dr3.jpg';


const abogados = [
	{ nombre: 'Dr. Fernando Diaz', imagen: Fernando },
	{ nombre: 'Dr. Gabriel Diaz', imagen: Gabriel },
	{ nombre: 'Dra. Marcela Diaz', imagen: Marcela },
	{ nombre: 'Dr. Mauricio Nogales', imagen: Mauricio },
	{ nombre: 'Dr. Julio Jerez', imagen: Julio },
	{ nombre: 'Dr. Federico Lostia', imagen: Federico },
	{ nombre: 'Dr. Jose Damuri', imagen: Jose },
	{ nombre: 'Dr. Diego Bruno', imagen: Diego },


];
export default function Equipo() {
	return (
		<div>
			<Container fluid className="section4 element" name="equipo">
				<Row>
					<Col className="" lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
						<div className="titulo">Nuestro equipo</div>
					</Col>
				</Row>
				<Row className="rowAbogados">
					{abogados.map((abogado, index) => {
						return (
							<Col
								key={index}
								className="containerAbogados"
								lg={{ span: 4 }}
								md={{ span: 6 }}
								sm={{ span: 6 }}
								xs={{ span: 12 }}
							>
								<div className="containerImgAbogado">
									<div
										className="imgAbogado"
										style={{
											backgroundImage: `url(${abogado.imagen})`
										}}
									>
										<div className="containerInfoAbogado">
											<div className="nombreAbogado">{abogado.nombre}</div>
											<div className="tituloAbogado">Abogado</div>
										</div>
									</div>
								</div>
							</Col>
						);
					})}
				</Row>
			</Container>
		</div>
	);
}
