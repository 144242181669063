import axios from 'axios';
import React, { useState } from 'react';
import { Col, Container, Row, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { Formik } from 'formik'; // Importamos el component <Formik />
import './Contacto.css';
import Fade from 'react-reveal/Fade';
// import Spinner from '../Spinner/Spinner';

const yup = require('yup');

export default function Contacto() {
	const [ messageResponse, setMessageResponse ] = useState();
	const [ loading, setLoading ] = useState(false);
	const [ showA, setShowA ] = useState(false);
	const toggleShowA = () => setShowA(!showA);

	const handleSubmit = async (values, { resetForm }) => {
		// console.log(values.nombre, values.apellido, values.mensaje, values.email, values.telefono);
		let fD = new FormData();
		fD.append('nombre', values.nombre);
		fD.append('apellido', values.apellido);
		fD.append('mensaje', values.mensaje);
		fD.append('email', values.email);
		fD.append('telefono', values.telefono);
		setLoading(true);

		await axios
			.post('https://api.estudioeje.com.ar/api/email', fD)
			.then((res) => {
				console.log(res);
				setLoading(false);
				setMessageResponse(res.data.message);
				setShowA(true);
				resetForm({});
			})
			.catch((e) => {
				console.log(e);
				setLoading(false);
				setMessageResponse(e.message);
				setShowA(true);
				resetForm({});
			});
	};

	const schema = yup.object({
		nombre: yup
			.string()
			.required('Ingrese un nombre..')
			.min(2, 'El nombre tiene que ser mayor a 2 caracteres..')
			.max(30, 'El nombre tiene que ser menor a 30 caracteres..'),
		apellido: yup
			.string()
			.required('Ingrese un apellido..')
			.min(2, 'El apellido tiene que ser mayor a 2 caracteres..')
			.max(30, 'El apellido tiene que ser menor a 30 caracteres..'),
		email: yup.string().email('Ingrese un correo valido..').required('Ingrese una direccion de correo correcta..'),
		mensaje: yup
			.string()
			.required('Ingrese un mensaje..')
			.min(10, 'El mensaje tiene que ser mayor a 10 caracteres..')
			.max(250, 'El apellido tiene que ser menor a 250 caracteres..'),
		telefono: yup
			.string()
			.required('Ingrese un telefono..')
			.min(5, 'El telefono tiene que ser mayor a 2 caracteres..')
			.max(15, 'El telefono tiene que ser menor a 15 caracteres..')
	});

	return (
		<Container className="section3">
			<Fade cascade as={Row}>
				<Col className="" lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
					<div initial="hidden" className="titulo">
						Servicio de Abono
					</div>
				</Col>
				<Col
					style={{ display: 'flex', justifyContent: 'center' }}
					lg={{ span: 12 }}
					md={{ span: 12 }}
					sm={{ span: 12 }}
					xs={{ span: 12 }}
				>
					<div className="sub-titulo">
						En estudio EJE, contamos con un servicio de abono que se ajustan a la realidad y capacidad de
						cada Empresa, Pymes, Organismo público y privado en sus diferentes ámbitos, Organizaciones
						civiles, Fundaciones, Sindicatos y Gremios, Establecimientos educativos, como así también a
						aquellos particulares que precisen un servicio de acuerdo a las áreas especializadas de Estudio
						EJE.
					</div>
				</Col>
				<Col style={{ height: '5vh' }} />
				<Col className="" lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
					<div className="titulo">Nos interesa ayudarte, escríbenos</div>
				</Col>
				<Col>
					<Formik
						validationSchema={schema}
						onSubmit={handleSubmit}
						initialValues={{
							nombre: '',
							apellido: '',
							email: '',
							mensaje: '',
							telefono: ''
						}}
					>
						{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
							<Form noValidate onSubmit={handleSubmit} className="pl-md-5 pr-md-5">
								<Form.Row>
									<Form.Group as={Col} md="4" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Control
											type="text"
											name="nombre"
											value={values.nombre}
											onChange={handleChange}
											isValid={touched.nombre && !errors.nombre}
											isInvalid={!!errors.nombre}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0,
												backgroundColor: '#FFFAF4'
											}}
										/>
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Nombre
										</Form.Text>
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.nombre}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="4" controlId="validationFormik02">
										{/* <Form.Label>Last name</Form.Label> */}
										<Form.Control
											type="text"
											name="apellido"
											value={values.apellido}
											onChange={handleChange}
											isValid={touched.apellido && !errors.apellido}
											isInvalid={!!errors.apellido}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0,
												backgroundColor: '#FFFAF4'
											}}
										/>
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Apellido
										</Form.Text>
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.apellido}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="4" controlId="validationFormikUsername">
										{/* <Form.Label>Email</Form.Label> */}
										<Form.Control
											type="email"
											name="email"
											value={values.email}
											onChange={handleChange}
											isValid={touched.email && !errors.email}
											isInvalid={!!errors.email}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0,
												backgroundColor: '#FFFAF4'
											}}
										/>
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Correo electronico
										</Form.Text>

										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} md="8" controlId="validationFormik03">
										{/* <Form.Label>Mensaje</Form.Label> */}
										<Form.Control
											type="text"
											name="mensaje"
											value={values.mensaje}
											onChange={handleChange}
											isValid={touched.mensaje && !errors.mensaje}
											isInvalid={!!errors.mensaje}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0,
												backgroundColor: '#FFFAF4'
											}}
										/>
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Mensaje
										</Form.Text>
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.mensaje}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="4" controlId="validationFormik04">
										{/* <Form.Label>Telefono</Form.Label> */}
										<Form.Control
											type="number"
											name="telefono"
											value={values.telefono}
											onChange={handleChange}
											isValid={touched.telefono && !errors.telefono}
											isInvalid={!!errors.telefono}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0,
												backgroundColor: '#FFFAF4'
											}}
										/>
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Telefono
										</Form.Text>
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.telefono}</Form.Control.Feedback>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} md="12" className="pr-0 form-button-contacto">
										<Button className="btn-enviar mt-2 mb-2" type="submit">
											{loading ? (
												<Spinner
													animation="grow"
													size="sm"
													style={{ marginBottom: '2px', marginRight: '3px' }}
												/>
											) : (
												<div />
											)}
											Enviar mensaje
										</Button>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									{showA ? (
										<Alert
											dismissible
											onClose={() => setShowA(false)}
											variant="info"
											className="w-100"
											style={{
												backgroundColor: 'transparent',
												borderTop: 'transparent',
												borderBottom: 'transparent',
												borderRadius: '0px',
												borderLeftColor: 'transparent',
												borderRightColor: 'transparent',
												boxShadow: '1px 1px 7px 1px rgba(0,0,0,0.45)'
											}}
										>
											{messageResponse}
										</Alert>
									) : (
										<div />
									)}
								</Form.Row>
							</Form>
						)}
					</Formik>
				</Col>
			</Fade>
		
			<div className="divider " />
		</Container>
	);
}
