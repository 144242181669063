import React from 'react';
import { Carousel } from 'react-responsive-carousel';

export default function GaleriaCarousel() {
	const IMAGES = [
		{
			src: require('../../assets/images/galeria/galeria1.jpg')
		},
		{
			src: require('../../assets/images/galeria/galeria2.jpg')
		},
		{
			src: require('../../assets/images/galeria/galeria3.jpg')
		},
		{
			src: require('../../assets/images/galeria/galeria5.jpg')
		},
		{
			src: require('../../assets/images/galeria/galeria4.jpg')
		},
		{
			src: require('../../assets/images/galeria/galeria4.jpg')
		}
	];
	return (
		<div>
			<div
				className="titulo d-block d-sm-block d-md-none d-lg-none d-xl-none"
				initial="hidden"
				transition={{ duration: 0.5 }}
			>
				Galería
			</div>
			<Carousel className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
				{IMAGES.map((img, index) => {
					return (
						<div key={index}>
							<img alt="" style={{ objectFit: 'contain' }} src={img.src} />
						</div>
					);
				})}
			</Carousel>
		</div>
	);
}
