import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

export class Mapa extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showingInfoWindow: false, // Hides or shows the InfoWindow
			activeMarker: {}, // Shows the active marker upon click
			selectedPlace: {}
		};
	}
	onMarkerClick = (props, marker, e) =>
		this.setState({
			selectedPlace: props,
			activeMarker: marker,
			showingInfoWindow: true
		});

	onClose = (props) => {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null
			});
		}
	};
	render() {
		const mapStyles = {
			padding: '0px',
			width: '100%'
			// height: '75vh'
		};

		return (
			<Map
				containerStyle={{
					padding: '0px',
					marginLeft: '0px',
					width: '100%',
					backgroundColor: 'blue'
					// height: '75vh'
				}}
				google={this.props.google}
				zoom={20}
				style={mapStyles}
				initialCenter={{
					lat: -24.782091,
					lng: -65.415202
				}}
			>
				<Marker onClick={this.onMarkerClick} name={'Eje Estudio'}  icon={{
      
    }} />
				<InfoWindow
					marker={this.state.activeMarker}
					visible={this.state.showingInfoWindow}
					onClose={this.onClose}
				>
					<div>
						<h2>{this.state.selectedPlace.name}</h2>
					</div>
				</InfoWindow>
			</Map>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: 'AIzaSyAA_F5CpfC6uZLJXoi4pdJ-IUki9JVOzHQ'
})(Mapa);

//AIzaSyDx3LpIS4EbOQXw0cO1mU2u65Jw1GhuiVE;
