import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Accordion, Card } from "react-bootstrap";
import { Link as Lnk } from "react-scroll";
import FacebookContorno from "../../assets/images/facebookcontorno.svg";
import InstagramContorno from "../../assets/images/instagramcontorno.svg";
import WhatsappContorno from "../../assets/images/whatsappcontorno.svg";
import logoJumpIn from "../../assets/images/icono-jump.svg";
import Logo from "../../assets/images/logo.png";
import "./Footer.css";

export default function Footer() {
  const [clicked, setClicked] = useState(false);
  const [clicked1, setClicked1] = useState(false);
  return (
    <Container fluid className="footer">
      <Container>
        <Row>
          <Col
            lg={{ span: 3 }}
            md={{ span: 3 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
            className="logoFooter"
          >
            <img alt="" className="logo" src={Logo} />
          </Col>
          <Col
            lg={{ span: 3 }}
            md={{ span: 3 }}
            sm={{ span: 4 }}
            xs={{ span: 4 }}
            className=" d-lg-block d-md-block d-sm-none d-none p-0"
          >
            <div className="empresaFooter">
              <ul className="list-footer">
                <li
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                  }}
                >
                  Estudio
                </li>
                <li id="itemLiFooter">
                  <Lnk
                    activeClass="active"
                    className="qsomos item-list-footer"
                    to="qsomos"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Quienes somos
                  </Lnk>
                </li>
                <li id="itemLiFooter">
                  <Lnk
                    activeClass="active"
                    className="equipo item-list-footer"
                    to="equipo"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Equipo
                  </Lnk>
                </li>
              </ul>
            </div>
          </Col>
          <Col
            lg={{ span: 3 }}
            md={{ span: 3 }}
            sm={{ span: 4 }}
            xs={{ span: 4 }}
            className=" d-lg-block d-md-block d-sm-none d-none p-0"
          >
            <div className="servicioFooter">
              <ul className="list-footer">
                <li
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                  }}
                >
                  Servicios
                </li>
                <li id="itemLiFooter">
                  <Lnk
                    activeClass="active"
                    className="servicio item-list-footer"
                    to="servicio"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Derecho Civil y Comercial
                  </Lnk>
                </li>
                <li id="itemLiFooter">
                  <Lnk
                    activeClass="active"
                    className="servicio item-list-footer"
                    to="servicio"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Derecho de Familia
                  </Lnk>
                </li>
                <li id="itemLiFooter">
                  <Lnk
                    activeClass="active"
                    className="servicio item-list-footer"
                    to="servicio"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Derecho Empresarial
                  </Lnk>
                </li>
                <li id="itemLiFooter">
                  <Lnk
                    activeClass="active"
                    className="servicio item-list-footer"
                    to="servicio"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Derecho Laboral
                  </Lnk>
                </li>
                <li id="itemLiFooter">
                  <Lnk
                    activeClass="active"
                    className="servicio item-list-footer"
                    to="servicio"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    Derecho del Consumidor
                  </Lnk>
                </li>
              </ul>
            </div>
          </Col>
          <Col
            lg={{ span: 3 }}
            md={{ span: 3 }}
            sm={{ span: 4 }}
            xs={{ span: 4 }}
            className="d-lg-block d-md-block d-sm-none d-none p-0"
          >
            <div className="contactoFooter">
              <ul className="list-footer">
                <li
                  style={{
                    fontSize: "26px",
                    fontWeight: "bold",
                  }}
                >
                  Contacto
                </li>
                <li id="itemLiFooter">contacto@estudioeje.com.ar</li>
                <li>
                  <a
                    href="https://www.instagram.com/estudioeje"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="icono-list-footer"
                      alt=""
                      src={InstagramContorno}
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/estudioeje"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt=""
                      className="icono-list-footer"
                      src={FacebookContorno}
                    />
                  </a>
                  <a
                    href="https://wa.me/543813153313?text=Hola,%20necesito%20comunicarme%20con%20mi%20Ustedes!%20"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt=""
                      className="icono-list-footer"
                      src={WhatsappContorno}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </Col>

          <Col
            className="d-lg-block d-md-block d-sm-none d-none pb-2 pt-2"
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <div
              class="dropdown-divider"
              style={{
                backgroundColor: "#167B82",
                borderTop: ".25px solid white",
                color: "teal !important",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                color: "white",
                fontFamily: "GilroyLight",
                fontSize: "15px",
              }}
            >
              <div style={{ fontWeight: "bolder" }}>
                Copyright © 2021 Estudio EJE. Todos los derechos reservados.
              </div>

              <div style={{ fontWeight: "bolder" }}>Salta, Argentina</div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                src={logoJumpIn}
                alt=""
                className="m-3"
                style={{ height: "40px", alignSelf: "center" }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className=" d-lg-none d-md-none d-sm-block d-block "
        style={{ paddingLeft: "0px", paddingRight: "0px" }}
      >
        <Accordion
          style={{ marginLeft: "0px", paddingLeft: "0px", borderRadius: "0px" }}
        >
          <Card
            style={{
              marginLeft: "0px",
              paddingLeft: "0px",
              borderRadius: "0px",
              borderWidth: "0px",
              backgroundColor: "transparent",
              color: "white",
            }}
          >
            <Accordion.Toggle
              as={Card.Header}
              style={{
                borderWidth: "0px",
                width: "100%",
                height: "60px",
                backgroundColor: "rgba(0, 123, 130, 1)",
                paddingLeft: "20px",
                paddingRight: "20px",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                outline: "none",
              }}
              eventKey="0"
              onClick={() => setClicked(!clicked)}
            >
              <div style={{ fontFamily: "GilroyLight" }}>Estudio</div>
              <div style={{ padding: "5px", fontSize: 30 }}>
                {<div className={`normal ${clicked && "openClick"} `}>+</div>}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "30px",
                  paddingRight: "20px",
                  fontFamily: "GilroyLight",
                }}
              >
                <Lnk
                  activeClass="active"
                  className="qsomos item-list-footer"
                  to="qsomos"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Quienes somos
                </Lnk>
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="0">
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "30px",
                  paddingRight: "20px",
                  fontFamily: "GilroyLight",
                }}
              >
                <Lnk
                  activeClass="active"
                  className="equipo item-list-footer"
                  to="equipo"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Equipo
                </Lnk>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card
            style={{
              marginLeft: "0px",
              borderRadius: "0px",
              borderWidth: "0px",
              backgroundColor: "transparent",
              color: "white",
            }}
          >
            <Accordion.Toggle
              as={Card.Header}
              style={{
                borderWidth: "0px",
                display: "flex",
                width: "100%",
                height: "60px",
                backgroundColor: "#167B82",
                color: "white",
                alignItems: "center",
                justifyContent: "space-between",
                paddingLeft: "20px",
                paddingRight: "20px",
                outline: "none",
              }}
              eventKey="1"
              onClick={() => setClicked1(!clicked1)}
            >
              <div style={{ fontFamily: "GilroyLight" }}>Servicios</div>
              <div style={{ padding: "5px", fontSize: 30 }}>
                {<div className={`normal ${clicked1 && "openClick"} `}>+</div>}
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "30px",
                  paddingRight: "20px",
                  fontFamily: "GilroyLight",
                }}
              >
                <Lnk
                  activeClass="active"
                  className="servicio item-list-footer"
                  to="servicio"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Derecho Civil y Comercial
                </Lnk>
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "30px",
                  paddingRight: "20px",
                  fontFamily: "GilroyLight",
                }}
              >
                <Lnk
                  activeClass="active"
                  className="servicio item-list-footer"
                  to="servicio"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Derecho de Familia
                </Lnk>
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "30px",
                  paddingRight: "20px",
                  fontFamily: "GilroyLight",
                }}
              >
                <Lnk
                  activeClass="active"
                  className="servicio item-list-footer"
                  to="servicio"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Derecho Empresarial
                </Lnk>
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "30px",
                  paddingRight: "20px",
                  fontFamily: "GilroyLight",
                }}
              >
                <Lnk
                  activeClass="active"
                  className="servicio item-list-footer"
                  to="servicio"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Derecho Laboral
                </Lnk>
              </Card.Body>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "30px",
                  paddingRight: "20px",
                  fontFamily: "GilroyLight",
                }}
              >
                <Lnk
                  activeClass="active"
                  className="servicio item-list-footer"
                  to="servicio"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Derecho de Consumidor y Usuarios
                </Lnk>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a
              href="https://www.instagram.com/estudioeje"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="icono-list-footer"
                src={InstagramContorno}
              />
            </a>
            <a
              href="https://www.facebook.com/estudioeje"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="icono-list-footer"
                src={FacebookContorno}
              />
            </a>
            <a
              href="https://wa.me/543875159478?text=Hola,%20necesito%20comunicarme%20con%20ustedes!%20"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt=""
                className="icono-list-footer"
                src={WhatsappContorno}
              />
            </a>
          </div>
          <div class="dropdown-divider" />
          <div
            style={{
              fontFamily: "GilroyLight",
              textAlign: "center",
              color: "white",
            }}
          >
            Salta, Argentina
          </div>
          <br />
          <div
            style={{
              fontFamily: "GilroyLight",
              textAlign: "center",
              color: "white",
            }}
          >
            Copyright © 2021 Estudio EJE.
            <br /> Todos los derechos reservados.
          </div>
          <br />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={logoJumpIn}
              className=""
              alt=""
              style={{ height: "40px", alignSelf: "center" }}
            />
          </div>
        </div>
      </Container>
    </Container>
  );
}
