import React, { useEffect, useState } from 'react';
import { Table, Button, InputGroup, Container, Modal } from 'react-bootstrap';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import ModalEdit from '../ModalEdit/ModalEdit';
import ModalNuevo from '../ModalNuevo/ModalNuevo';
import ModalDelete from '../ModalDelete/ModalDelete';

export default function NoticiasAdmin() {
	const [ loadingNoticias, setLoadingNoticias ] = useState(false);
	const [ noticiasAPI, setNoticiasAPI ] = useState([]);
	const [ noticia, setNoticia ] = useState({});

	const [ showNew, setShowNew ] = useState(false);
	const [ showEdit, setShowEdit ] = useState(false);
	const [ showDelete, setShowDelete ] = useState(false);

	const [ isNew, setIsNew ] = useState(false);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ isDelete, setIsDelete ] = useState(false);

	const handleCloseDelete = () => setShowDelete(false);
	const handleIsDelete = () => setIsDelete(false);

	const handleCloseEdit = () => setShowEdit(false);
	const handleCloseNew = () => setShowNew(false);

	const handleIsNew = () => setIsNew(false);
	const handleIsEdit = () => setIsEdit(false);

	const getNoticias = async () => {
		await axios
			.get(`https://api.estudioeje.com.ar/api/noticias`, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
				}
			})
			.then((res) => {
				// console.log(res.data.noticias);
				if (res.data.noticias != null) {
					setNoticiasAPI(res.data.noticias);
				}
			});
	};

	useEffect(() => {
		getNoticias();
	}, []);

	return (
		<div>
			<Container fluid>
				<Button
					className="bg-teal btn-enviar"
					style={{}}
					onClick={() => {
						setIsNew(true);
						setShowNew(true);
					}}
				>
					+ Noticia
				</Button>
				<h2>Listado de todas las noticias registradas</h2>
				<Table
					responsive="sm"
					striped
					bordered
					hover
					size="sm"
					className="w-100"
					style={{ overflowX: 'visible !important' }}
				>
					<thead>
						<tr>
							<th>Titulo</th>
							<th>Subtitulo</th>
							<th>Cuerpo</th>
							<th>Fecha</th>
							<th>Hora</th>
							<th>Visible</th>
							<th>Eliminada</th>
							<th>Accion</th>
						</tr>
					</thead>
					<tbody>
						{noticiasAPI.map((noticia) => {
							return (
								<tr key={noticia.id}>
									<td>{noticia.titulo}</td>
									<td>{noticia.subtitulo}</td>
									<td>{noticia.cuerpo}</td>
									<td>{noticia.fecha}</td>
									<td>{noticia.hora}</td>
									<td>
										<InputGroup className="mb-3">
											<InputGroup.Prepend>
												{noticia.visible === 0 ? (
													<InputGroup.Checkbox aria-label="Checkbox for following text input" />
												) : (
													<InputGroup.Checkbox
														checked
														aria-label="Checkbox for following text input"
													/>
												)}
											</InputGroup.Prepend>
										</InputGroup>
									</td>
									<td>
										<InputGroup className="mb-3">
											<InputGroup.Prepend>
												{noticia.eliminada === 0 ? (
													<InputGroup.Checkbox aria-label="Checkbox for following text input" />
												) : (
													<InputGroup.Checkbox
														checked
														aria-label="Checkbox for following text input"
													/>
												)}
											</InputGroup.Prepend>
										</InputGroup>
									</td>
									<td style={{ display: 'flex' }}>
										<div
											className="bg-info"
											style={{
												margin: '2px',
												padding: '3px',
												borderRadius: '3px',
												cursor: 'pointer',
												color: 'white',
												boxShadow: ' 0px 0px 3px 1px rgba(0,0,0,.3)'
											}}
										>
											<EditIcon
												onClick={() => {
													setNoticia(noticiasAPI.find((not) => not.id === noticia.id));
													setIsEdit(true);
													setShowEdit(true);
												}}
											/>
										</div>
										<div
											className="bg-danger"
											style={{
												margin: '2px',
												padding: '3px',
												borderRadius: '3px',
												cursor: 'pointer',
												color: 'white',
												boxShadow: ' 0px 0px 3px 1px rgba(0,0,0,.3)'
											}}
										>
											<DeleteIcon
												onClick={() => {
													setNoticia(noticia);
													setIsDelete(true);
													setShowDelete(true);
												}}
											/>
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Container>
			{isDelete ? (
				<ModalDelete
					handleIsDelete={handleIsDelete}
					noticia={noticia}
					show={showDelete}
					handleClose={handleCloseDelete}
					handleGetNoticias={getNoticias}
				/>
			) : (
				<div />
			)}
			{isEdit ? (
				<ModalEdit
					handleIsEdit={handleIsEdit}
					handleGetNoticias={getNoticias}
					noticia={noticia}
					show={showEdit}
					handleClose={handleCloseEdit}
				/>
			) : (
				<div />
			)}
			{isNew ? (
				<ModalNuevo
					handleIsNew={handleIsNew}
					handleGetNoticias={getNoticias}
					show={showNew}
					handleClose={handleCloseNew}
				/>
			) : (
				<div />
			)}
		</div>
	);
}
