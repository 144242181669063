import React from 'react';
import LoginAdmin from '../Components/Admin/Components/Login/LoginAdmin';
import InicioAdmin from '../Components/Admin/InicioAdmin';
import Inicio from '../Components/Inicio/Inicio';
import Noticia from '../Components/Noticias/Noticia';
import Noticias from '../Components/Noticias/Noticias';

const routes = [
	{
		path: '/home',
		component: Inicio,
		isPrivate: false
	},
	{
		path: '/noticias',
		component: Noticias,
		isPrivate: false
	},
	{
		path: '/noticia/:id',
		component: Noticia,
		isPrivate: false
	},
	{
		path: '/login',
		component: LoginAdmin,
		isPrivate: false
	},
	{
		path: '/admin',
		component: InicioAdmin,
		isPrivate: true
	},
	{
		path: '/*',
		component: Inicio,
		isPrivate: false
	}
];

export default routes;
