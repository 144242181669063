import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import "./NavBar1.css";
import { Link as Lnk, animateScroll as scroll } from "react-scroll";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/images/logocolor.png";

export default function NavBar1() {
  const [show, handleShow] = useState(false);
  // const fadeUp = {
  // 	hidden: { opacity: 0 },
  // 	visible: { opacity: 1 }
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 160) {
        handleShow(true);
      } else handleShow(false);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div>
      <motion.nav
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className={`navbar-custom ${show && "navbar-custom-dark"}`}
      >
        <input id="nav-toggle" type="checkbox" />
        <div className="containerLogo1">
          <Link className="liNav" to="/" onClick={() => scroll.scrollToTop()}>
            <img alt="" className="imgLogo1" src={Logo} />
          </Link>
        </div>
        <ul className={`links ${show && "links-hide-border"}`}>
          <li className="">
            <Lnk
              activeClass="active"
              className="qsomos liNav"
              to="qsomos"
              spy={true}
              smooth={true}
              duration={500}
            >
              Nosotros
            </Lnk>
          </li>
          <li className="">
            <Lnk
              activeClass="active"
              className="servicio liNav"
              to="servicio"
              spy={true}
              smooth={true}
              duration={500}
            >
              Servicios
            </Lnk>
          </li>
          <li>
            <div>
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                  color: "red",
                  scale: 1.3,
                }}
                className="liNav"
                to="/"
                onClick={() => scroll.scrollToTop()}
              >
                <img alt="" className="imgLogoNav" src={Logo} />
              </NavLink>
            </div>
          </li>
          <li className="">
            <Lnk
              activeClass="active"
              className="equipo liNav"
              to="equipo"
              spy={true}
              smooth={true}
              duration={500}
            >
              Equipo
            </Lnk>
          </li>
          <li className="">
            <Link className="liNav" to="noticias">
              Noticias
            </Link>
          </li>
        </ul>
        <div className="btn-menu">
          <div className="line" />
          <div className="line" />
        </div>
        <label for="nav-toggle" className="icon-burger">
          <div className="line" />
          <div className="line" />
        </label>
      </motion.nav>
    </div>
  );
}
