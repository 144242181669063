import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar2 from "../NavBar/NavBar2";
import Spinner from "../Spinner/Spinner";
import { Carousel } from "react-responsive-carousel";

import "./Noticia.css";
import { MetaTags } from "react-meta-tags";
export default function Noticia({ match }) {
  const [noticiaUnica, setNoticiaUnica] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const getNoticia = async () => {
    setLoading(true);
    await axios
      .get(`https://api.estudioeje.com.ar/api/noticiasWeb/${match.params.id}`, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        if (res.data.noticia.length > 0) {
          console.log(res.data.noticia);
          setNoticiaUnica(res.data.noticia);
          setLoading(false);
        } else {
          setIsInvalid(true);
          setLoading(false);
        }
      });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getNoticia();
  }, []);

  return (
    <div>
      <NavBar2 />
      <MetaTags>
        <title>Noticia - Estudio Eje</title>
        <meta
          name="description"
          content="Nosotros nos encargamos de solucionarte tus cosas"
        />
        <meta property="og:title" content="Noticia - Estudio Jurídico EJE" />
        <meta property="og:image" content="./logocolor.png" />
      </MetaTags>      <Container className="containerNoticiaIndividual">
        <div
          style={{
            color: "rgba(0,123,130)",
            fontSize: "calc(0.8rem + 0.8vw)",
            fontWeight: "bolder",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          NOTICIA
        </div>

        <Row>
          {isInvalid ? (
            <div style={{ paddingLeft: "15px" }}>Noticia no encontrada.</div>
          ) : !loading ? (
            noticiaUnica.map((not) => {
              return (
                <Container style={{ minHeight: "600px" }}>
                  <Row>
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      xl={6}
                      className="colImagenNoticia"
                    >
                      {/* <motion.div
												initial={{ opacity: 0 }}
												animate={{ opacity: 1 }}
												transition={{ duration: 1 }}
												style={{
													backgroundImage: `${not.noticia_imagenes.length > 0
														? `url(${not.noticia_imagenes[0].enlace})`
														: `src("../../assets/images/derecho-civil.jpg")`}`,
													backgroundSize: 'contain',
													backgroundRepeat: 'no-repeat',
													width: '100%',
													height: '100%',
													backgroundColor: 'transparent'
												}}
											/> */}
                      <Carousel showThumbs={false} className="">
                        {not.noticia_imagenes.map((img, index) => {
                          return (
                            <div key={index} className="">
                              <img
                                alt=""
                                style={{
                                  objectFit: "cover",
                                  width: "auto",
                                  height: "400px",
                                }}
                                src={`${img.enlace}`}
                              />
                            </div>
                          );
                        })}
                      </Carousel>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={6}
                      xl={6}
                      className="colTextoNoticia"
                    >
                      <div
                        style={{
                          width: "100%",
                          backgroundColor: "transparent",
                          backgroundPosition: "0 60px",
                          overflow: "hidden",
                          fontWeight: "bold",
                          fontSize: "calc(0.7rem + 0.7vw)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1 }}
                          className="h1"
                          style={{
                            wordBreak: "break-word",
                            paddingLeft: "1px",
                          }}
                        >
                          {not.titulo}
                        </motion.div>
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ duration: 1, delay: 0.5 }}
                          className="h4"
                          style={{
                            wordBreak: "break-word",
                            paddingLeft: "1px",
                          }}
                        >
                          {not.subtitulo}
                        </motion.div>
                      </div>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1, delay: 0.7 }}
                        style={{
                          fontSize: "calc(0.5rem + 0.5vw)",
                          wordBreak: "break-word",
                          paddingLeft: "1px",
                        }}
                      >
                        {not.cuerpo}
                      </motion.div>
                    </Col>
                  </Row>
                </Container>
              );
            })
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spinner height={"55px"} width={"40px"} />
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
}
