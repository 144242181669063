import React, { useState } from 'react';
import { Alert, Button, Col, Form, Modal, Spinner } from 'react-bootstrap';
import { Formik } from 'formik'; // Importamos el component <Formik />
import axios from 'axios';
import { useAuthState } from '../../../Context/LoginRegister';
import { useHistory } from 'react-router';

const yup = require('yup');

const schema = yup.object({
	titulo: yup
		.string()
		.required('Ingrese un titulo..')
		.min(2, 'El titulo tiene que ser mayor a 2 caracteres..')
		.max(100, 'El titulo tiene que ser menor a 100 caracteres..'),

	cuerpo: yup
		.string()
		.required('Ingrese el contenido..')
		.min(2, 'El contenido tiene que ser mayor a 2 caracteres..')
		.max(500, 'El contenido tiene que ser menor a 500 caracteres..')
});

export default function ModalEditNovedad({ novedad, show, handleClose, handleIsEdit, handleGetNovedades }) {
	const userDetails = useAuthState();
	const [ loading, setLoading ] = useState(false);
	const [ messageResponse, setMessageResponse ] = useState();
	const [ novedadAPI, setNovedadAPI ] = useState(novedad);

	let history = useHistory();

	const getNovedad = async () => {
		await axios
			.get(`https://api.estudioeje.com.ar/api/novedad/${novedad.id}`, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
				}
			})
			.then((res) => {
				// console.log(res.data);
				setNovedadAPI(res.data.novedad[0]);
			})
			.catch((e) => {
				// console.log(e);
			});
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		// console.log(values.eliminada);
		let formData = new FormData();
		formData.append('titulo', values.titulo);
		formData.append('cuerpo', values.cuerpo);
		formData.append('fecha', values.fecha);
		formData.append('hora', values.hora);
		formData.append('fechainicio', values.fechainicio);
		formData.append('fechafin', values.fechafin);
		formData.append('horainicio', values.horainicio);
		formData.append('horafin', values.horafin);
		formData.append('eliminada', values.eliminada);
		formData.append('id', userDetails.user.id);
		formData.append('_method', 'put');
		await axios
			.post(`https://api.estudioeje.com.ar/api/novedades/${novedad.id}`, formData, {
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
				}
			})
			.then((res) => {
				// console.log(res);
				setLoading(false);
				setMessageResponse(res.data.message);
				getNovedad();
				handleGetNovedades();
				// history.push('/admin/novedades');
			})
			.catch((e) => {
				setLoading(false);
				setMessageResponse(e.message);
			});
	};

	return (
		<div>
			<Modal keyboard={false} backdrop="static" size="xl" show={show} onHide={handleClose}>
				<Modal.Header>
					<Modal.Title>Editar novedad</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						validationSchema={schema}
						onSubmit={handleSubmit}
						initialValues={{
							titulo: novedadAPI.titulo,
							cuerpo: novedadAPI.cuerpo,
							fecha: novedadAPI.fecha,
							hora: novedadAPI.hora,
							fechainicio: novedadAPI.fechainicio,
							fechafin: novedadAPI.fechafin,
							horainicio: novedadAPI.horainicio,
							horafin: novedadAPI.horafin,
							eliminada: novedadAPI.eliminada === 0 ? false : true
						}}
					>
						{({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
							<Form noValidate onSubmit={handleSubmit} className="pl-md-5 pr-md-5">
								<Form.Row>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Titulo
										</Form.Text>
										<Form.Control
											type="text"
											name="titulo"
											value={values.titulo}
											onChange={handleChange}
											isValid={touched.titulo && !errors.titulo}
											isInvalid={!!errors.titulo}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
									
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.titulo}</Form.Control.Feedback>
									</Form.Group>

									<Form.Group as={Col} md="12" controlId="validationFormik03">
										{/* <Form.Label>cuerpo</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Cuerpo
										</Form.Text>
										<Form.Control
											type="cuerpo"
											name="cuerpo"
											value={values.cuerpo}
											onChange={handleChange}
											isValid={touched.cuerpo && !errors.cuerpo}
											isInvalid={!!errors.cuerpo}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
									

										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.cuerpo}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Fecha
										</Form.Text>
										<Form.Control
											type="date"
											name="fecha"
											value={values.fecha}
											onChange={handleChange}
											isValid={touched.fecha && !errors.fecha}
											isInvalid={!!errors.fecha}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
										
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.fecha}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Hora
										</Form.Text>
										<Form.Control
											type="time"
											name="hora"
											value={values.hora}
											onChange={handleChange}
											isValid={touched.hora && !errors.hora}
											isInvalid={!!errors.hora}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
									
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.hora}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Fecha inicio
										</Form.Text>
										<Form.Control
											type="date"
											name="fechainicio"
											value={values.fechainicio}
											onChange={handleChange}
											isValid={touched.fechainicio && !errors.fechainicio}
											isInvalid={!!errors.fechainicio}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
										
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">
											{errors.fechainicio}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Fecha fin
										</Form.Text>
										<Form.Control
											type="date"
											name="fechafin"
											value={values.fechafin}
											onChange={handleChange}
											isValid={touched.fechafin && !errors.fechafin}
											isInvalid={!!errors.fechafin}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
										
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.fechafin}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Hora inicio
										</Form.Text>
										<Form.Control
											type="time"
											name="horainicio"
											value={values.horainicio}
											onChange={handleChange}
											isValid={touched.horainicio && !errors.horainicio}
											isInvalid={!!errors.horainicio}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
									
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">
											{errors.horainicio}
										</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Hora fin
										</Form.Text>
										<Form.Control
											type="time"
											name="horafin"
											value={values.horafin}
											onChange={handleChange}
											isValid={touched.horafin && !errors.horafin}
											isInvalid={!!errors.horafin}
											style={{
												border: '0',
												borderBottom: '1px solid rgba(66,62,55,.2)',
												borderRadius: 0
											}}
										/>
									
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.horafin}</Form.Control.Feedback>
									</Form.Group>
									<Form.Group as={Col} md="12" controlId="validationFormik01">
										{/* <Form.Label>First name</Form.Label> */}
										<Form.Text
											className="text-muted h5"
											style={{ color: 'rgba(66,62,55,.2) !important' }}
										>
											Eliminada
										</Form.Text>
										<Form.Check
											defaultChecked={novedad.eliminada === 0 ? false : true}
											name="eliminada"
											value={values.eliminada}
											onChange={handleChange}
											type={'checkbox'}
										/>
										
										<Form.Control.Feedback>Correcto!</Form.Control.Feedback>
										<Form.Control.Feedback type="invalid">{errors.horafin}</Form.Control.Feedback>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} md="12" className="pr-5">
										<Button className="float-md-right btn-enviar" type="submit">
											{loading ? (
												<Spinner
													animation="border"
													size="sm"
													style={{ marginBottom: '2px', marginRight: '3px' }}
												/>
											) : (
												<div style={{ display: 'none' }} />
											)}
											Confirmar edicion
										</Button>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									{messageResponse != null ? (
										<Alert variant="info" className="w-100 ">
											{messageResponse}
										</Alert>
									) : (
										<div />
									)}
								</Form.Row>
							</Form>
						)}
					</Formik>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary btn-enviar"
						onClick={() => {
							handleIsEdit(false);
							handleClose(false);
						}}
					>
						Volver
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
